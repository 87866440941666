<mat-toolbar>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
    [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['/crypto']" routerLinkActive="mat-accent">Crypto</button>
    <button mat-menu-item>Item 2</button>
  </mat-menu>
  <a href="/"><span>Culpepper.US</span></a>
  <span class="example-spacer"></span>
  <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button> -->
</mat-toolbar>
